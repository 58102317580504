﻿/*
 * ==============================================================
 * Bobaweb Custom Handlers
 * Intended use for unique script for each web
 * ==============================================================
 * ==============================================================
 * ██████╗  ██████╗ ██████╗  █████╗ ██╗    ██╗███████╗██████╗
 * ██╔══██╗██╔═══██╗██╔══██╗██╔══██╗██║    ██║██╔════╝██╔══██╗
 * ██████╔╝██║   ██║██████╔╝███████║██║ █╗ ██║█████╗  ██████╔╝
 * ██╔══██╗██║   ██║██╔══██╗██╔══██║██║███╗██║██╔══╝  ██╔══██╗
 * ██████╔╝╚██████╔╝██████╔╝██║  ██║╚███╔███╔╝███████╗██████╔╝
 * ╚═════╝  ╚═════╝ ╚═════╝ ╚═╝  ╚═╝ ╚══╝╚══╝ ╚══════╝╚═════╝
 * ==============================================================
 * ==============================================================
 */

'use strict';

//import 'bootstrap/dist/js/bootstrap.bundle';

// opening edit profile
// in my account page
$('#open-edit-profile').on('click', function (e) {
    e.preventDefault();

    // getting the target container
    let target = $(this).data('target');

    // make instance target as jQuery instance
    let $target = $(target).first();

    // toggling the class;
    $target.toggleClass('d-none');
});

//$('#promoModal').modal('show');

// add wishlist callbacks
window.AddWishlistCallback = function ($elem, productId) {
    ////$elem.addClass('wishlisted');
}

// remove wishlist callback
window.RemoveWishlistCallback = function ($elem, productId) {
    //$elem.removeClass('wishlisted');
}

// remove wishlist callback
// on profile page
window.RemoveWishlistProfile = function ($elem, productId) {

    let elemToRemove = document.querySelector(`[data-identity="wishlist-profile-item"][data-id="${productId}"]`);

    elemToRemove.remove();

    EmptyWishlistDisplay();
}

function EmptyWishlistDisplay() {

    let wishlistNumbers = document.querySelectorAll('[data-identity="wishlist-profile-item"]').length;

    if (wishlistNumbers <= 0) {

        let wishlistContainer = document.querySelector('[data-identity="wishlist-container"]');

        // removing the wishlist display
        document.querySelector(`.wishlist-tbody`).remove();

        // appending the empty wishlist display
        wishlistContainer.insertAdjacentHTML('beforeend', `<tbody class="no-wishlist-tbody">
                <tr>
                    <td>
                        <p>You currently don't have any wishlist!</p>
                        <p><a href="/products" class="text-dark" style="text-decoration: underline">Take a look at our catalogue right here</a></p>
                    </td>
                </tr>
            </tbody>`);
    }

}

// delete address callback
// on profile page
window.deleteAddressProfileCallback = function (id, elem) {

    let addressDiv = document.querySelector(`#address-${id}`);

    addressDiv.parentElement.removeChild(addressDiv);

}

// delete address prompt
// on profile page
window.deleteAddressProfilePrompt = function () {
    return new Promise((rs, rj) => {

        let deleteConfirm = confirm('Are you sure you want to delete this address?');

        if (deleteConfirm) {
            rs(true);
        }
        else {
            rj(false);
        }

    });
}

// delete cart item prompt
window.deleteCartItem = function (itemInfo) {

    return new Promise((rs, rj) => {

        $('.bw-isla-delete-cart-item__img')
            .css('background', `url(${itemInfo.imageUrl})`);

        $('.bw-isla-delete-cart-item__title')
            .text(itemInfo.productName);

        $('.isla-button.yes-delete').off().click(function () {
            $('.bw-isla-delete-cart').removeClass('show-notif');

            rs(true);
        })

        $('.bw-isla-close').off().click(function () {

            $('.bw-isla-delete-cart').removeClass('show-notif');
            rj();
        })

        $('.bw-isla-delete-cart').addClass('show-notif');
    });

}